import React, { useEffect } from "react";
import Login from "../components/Login";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../components/Theme";


const LoginPage = () => {

    // No index page
    return (
        <div>
            <title>Assess Threat</title>
            <ThemeProvider theme={theme}>
                    <Login />
            </ThemeProvider>
        </div>
    )
}

export default LoginPage